import { Button, Header, Modal, Text } from '@gasbuddy/react-components';
import loadable from '@loadable/component';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useRouteMatch } from 'react-router-dom';
import SmartBanner from 'react-smartbanner';
import useAnalytics from '../../../lib/hooks/useAnalytics';
import useConfig from '../../../lib/hooks/useConfig';
import useGoogleOneTap from '../../../lib/hooks/useGoogleOneTap';
import usePageContext from '../../../lib/hooks/usePageContext';
import useProfile from '../../../lib/hooks/useProfile';
import { canonicalizeString, scrollToTop } from '../../../lib/utils';
import ReactGTM from '../../../lib/utils/gtm';
import { ANALYTICS_EVENTS, BANNER_ADS, LOGIN_STATUS } from '../../constants';
import { DESCRIPTION, THUMBNAIL, TITLE } from '../../constants/helmet';
import PageContext from '../../context/page';
import { AdParams, AdSizeMappingPropType, AdSizesPropType } from '../../prop-types/ad';
import { AdBanner } from '../AdBanner';
import AlertBanner from '../AlertBanner';
import DealAlertStatus from '../DealAlertStatus';
import DeleteAccountTray from '../DeleteAccountTray';
import ErrorBoundary from '../ErrorBoundary';
import MainSchema from '../MainSchema';
import styles from './Page.module.css';

const cx = classnames.bind(styles);

const SocialSignupLazyComponent = loadable(() => import('./SocialSignup'));

export default function Page({
  activeNavKey,
  adParams,
  bannerAds,
  breadcrumbSchema,
  children,
  className,
  compact,
  description,
  isPartner,
  logScreenView,
  meta,
  name: pageName,
  primaryImageObjDetails,
  productSchema,
  schema,
  showAppBanner,
  title,
  withTopAd,
}) {
  const { accountId, deletionStatus, emailHash, isLoadingContext, isPayUser, requestedDNS } = useProfile();
  const { googleAppId, identityHost, env } = useConfig();
  const analytics = useAnalytics();
  const { url: path } = useRouteMatch();
  const [showMismatchModal, setShowMismatchModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [oneTapError, setOneTapError] = useState();
  const { setPageName, setNavKey } = usePageContext();

  useEffect(() => {
    setPageName(pageName);
    setNavKey(activeNavKey);
  }, [activeNavKey, pageName, setNavKey, setPageName]);

  const isLoggedOut = !accountId;
  const oneTapLoginResult = useGoogleOneTap(googleAppId, !isLoggedOut);

  const reloadPage = useCallback(() => {
    window.location.reload();
  }, []);

  const redirectToIdentity = useCallback(() => {
    window.location = `//${identityHost}`;
  }, [identityHost]);

  const toggleMismatchModal = useCallback(() => {
    setShowMismatchModal(prevStatus => !prevStatus);
  }, []);

  const toggleSignupModal = useCallback(() => {
    setShowSignupModal(prevStatus => !prevStatus);
  }, []);

  const handleBannerCloseClicked = useCallback(() => {
    analytics.tagEvent({
      name: ANALYTICS_EVENTS.APP_BANNER_CLOSE_CLICKED,
      attributes: {
        'Screen Name': pageName,
      },
    });
  }, [analytics, pageName]);

  const handleBannerInstallClicked = useCallback(() => {
    analytics.tagEvent({
      name: ANALYTICS_EVENTS.APP_BANNER_INSTALL_CLICKED,
      attributes: {
        'Screen Name': pageName,
      },
    });
  }, [analytics, pageName]);

  // NOTE: Google OneTap login is handled through useGoogleOneTap hook only
  // whereas signup is handled through a component exported from react-consumer-components
  // which is why you will observe response format different from the one received with authResult
  const onSocialFormSignup = useCallback(({ data: socialData }) => {
    toggleSignupModal();
    if (socialData?.response?.accountId) {
      analytics.tagEvent({
        name: ANALYTICS_EVENTS.REGISTERED,
        attributes: { Method: 'googleonetap' },
      });

      ReactGTM.event({
        event: 'SignupComplete',
        userID: socialData.response.accountId,
        method: 'googleonetap',
      });

      reloadPage();
    }
  }, [analytics, reloadPage, toggleSignupModal]);

  useEffect(() => {
    if (isLoggedOut && oneTapLoginResult) {
      if (oneTapLoginResult.status === 'success') {
        analytics.tagEvent({
          name: ANALYTICS_EVENTS.LOGGED_IN,
          attributes: { Method: 'googleonetap' },
        });

        ReactGTM.event({
          event: 'LoginComplete',
          userID: oneTapLoginResult.accountId,
          method: 'googleonetap',
        });

        reloadPage();
      } else if (oneTapLoginResult.code && Object.values(LOGIN_STATUS).includes(oneTapLoginResult.code)) {
        setOneTapError(oneTapLoginResult);
        if (oneTapLoginResult.response.code === LOGIN_STATUS.AccountNotFound) {
          // toggle signupform that allows users to signup in consumer-web
          toggleSignupModal();
        } else if (oneTapLoginResult.code === LOGIN_STATUS.AccountNotLinked) {
          // redirect to identity with preloaded state in order to link accounts
          window.location = `//${identityHost}/social/login/google?token=${oneTapLoginResult.credential}`;
        } else if (oneTapLoginResult.code === LOGIN_STATUS.EmailMismatch && typeof oneTapLoginResult.credential !== 'undefined') {
          toggleMismatchModal();
        }
      }
    }
  }, [oneTapLoginResult, isLoggedOut, analytics, identityHost, reloadPage, toggleSignupModal, toggleMismatchModal]);

  useEffect(() => {
    scrollToTop();
    if (logScreenView) {
      analytics.tagPageView({ name: pageName, attributes: meta });
      analytics.tagEvent({
        name: 'Screen Viewed',
        attributes: { ...meta, 'Screen Name': pageName },
      });
    }
  }, [analytics, logScreenView, meta, pageName]);

  const cleanPath = path.endsWith('/') ? path.substring(0, path.lastIndexOf('/')) : path;
  const canonicalPath = canonicalizeString(cleanPath, true);

  const pageTitle = title || TITLE;
  const pageDescription = description || DESCRIPTION;

  return (
    <PageContext.Provider value={{ pageName }}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta content="IE=edge,chrome=1" httpEquiv="X-UA-Compatible" />
        <meta name="google-site-verification" content="zXhVMaZ-JnjK_v5xNuzXXzO99G76HlIJhiyP31rNWsQ" />
        <meta name="facebook-domain-verification" content="9i95xs7r3tr0pkido9vsncfgkqyipu" />
        <meta name="itunes-apple-app" content="app-id=406719683, app-argument=https://itunes.apple.com/us/app/gasbuddy-find-cheap-gas-prices/id406719683?mt=8&uo=4" />
        <meta name="google-play-app" content="app-id=gbis.gbandroid" />
        <meta name="robots" content={env === 'production' ? 'max-snippet:-1, max-image-preview:large, max-video-preview:-1' : 'noindex, nofollow'} />
        <meta name="description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:image" content={THUMBNAIL} />
        <meta property="og:description" content={pageDescription} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:image" content={THUMBNAIL} />
        <meta name="twitter:description" content={pageDescription} />
        <link rel="canonical" href={`https://www.gasbuddy.com${canonicalPath}`} />
        <link rel="shortcut icon" id="favicon" type="image/x-icon" href="https://static.gasbuddy.com/web/favicon.ico" />
        <link rel="apple-touch-icon" sizes="152x152" href="https://images.gasbuddy.com/images/websites/gasbuddy/apple-touch-icon-precomposed.png" />
        <link rel="android-touch-icon" href="https://lh3.googleusercontent.com/7lBT8GkGNFANCkpmD9JEPua2C5iwg_3gW7-EVmmpQ8Z1iTaxY581FewPiipH-VI4gwA=w170" />
        <link rel="alternate" href="android-app://gbis.gbandroid/gasbuddy/" />
        <script async defer src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" type="text/javascript" />
        <script type="text/javascript">
          {`
            window.googletag = window.googletag || {cmd: []};
          `}
        </script>
        <script type="text/javascript">
          {`
            !function(a9,a,p,s,t,A,g){if(a[a9])return;function q(c,r){a[a9]._Q.push([c,r])}a[a9]={init:function(){q("i",arguments)},fetchBids:function(){q("f",arguments)},setDisplayBids:function(){},targetingKeys:function(){return[]},_Q:[]};A=p.createElement(s);A.async=!0;A.src=t;g=p.getElementsByTagName(s)[0];g.parentNode.insertBefore(A,g)}("apstag",window,document,"script","//c.amazon-adsystem.com/aax2/apstag.js");

            apstag.init({
              pubID: '3615',
              adServer: 'googletag',
              bidTimeout: 30 * 1000
            });
          `}
        </script>
        {!isLoadingContext && !requestedDNS && (
          <script type="text/javascript" src={`https://aa.agkn.com/adscores/s.js?sid=9112323488&em=${emailHash || ''}`} />
        )}
      </Helmet>
      <Helmet>
        <script src="https://accounts.google.com/gsi/client" async defer />
      </Helmet>
      {showAppBanner && (
        <div className={cx('bannerWrapper')}>
          <SmartBanner
            appMeta={{
              android: 'google-play-app',
              ios: 'itunes-apple-app',
            }}
            button="GET"
            daysReminder={30}
            ignoreIosVersion
            onClose={handleBannerCloseClicked}
            onInstall={handleBannerInstallClicked}
            position="top"
            title="GasBuddy: Find Cheap Gas"
          />
        </div>
      )}
      <Modal
        content={({ close }) => (
          <div className={cx('mismatchModal')}>
            <Header as="h2">Sorry, we can&apos;t log you in at the current time.</Header>
            <Text as="p">Please log in with your email or continue browsing GasBuddy as a guest.</Text>
            <br />
            <Button onClick={redirectToIdentity} primary wide>Log in</Button>
            <Button onClick={close}>Continue as Guest</Button>
          </div>
        )}
        onClose={toggleMismatchModal}
        size="sm"
        forceIsShowing={showMismatchModal}
      />
      {
        showSignupModal && (
          <SocialSignupLazyComponent
            identityHost={identityHost}
            email={oneTapError?.email}
            credential={oneTapLoginResult?.credential}
            onSocialFormSignup={onSocialFormSignup}
            toggleSignupModal={toggleSignupModal}
            showSignupModal={showSignupModal}
          />
        )
      }
      <MainSchema
        breadcrumbSchema={breadcrumbSchema}
        description={description}
        primaryImageObjDetails={primaryImageObjDetails}
        productSchema={productSchema}
        schema={schema}
        title={title}
      />
      {withTopAd && (
        <AdBanner paths={bannerAds.length ? bannerAds : BANNER_ADS} params={adParams} />
      )}
      <AlertBanner featureName="homepage_banner" isPartnerPage={isPartner} />
      <ErrorBoundary>
        <div className={cx('content', { padded: !compact }, className)}>
          {children}
        </div>
      </ErrorBoundary>
      {deletionStatus && ['blocked', 'processing'].includes(deletionStatus.status) ? (
        <DeleteAccountTray
          endDate={deletionStatus.endDate}
          isProcessing={deletionStatus.status === 'processing'}
        />
      ) : (
        <DealAlertStatus isPwGBMember={isPayUser} />
      )}
    </PageContext.Provider>
  );
}

Page.propTypes = {
  activeNavKey: PropTypes.string,
  adParams: AdParams,
  bannerAds: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    path: PropTypes.string,
    sizes: AdSizesPropType.isRequired,
    sizeMapping: AdSizeMappingPropType,
  })),
  breadcrumbSchema: PropTypes.object, // eslint-disable-line
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  compact: PropTypes.bool,
  description: PropTypes.string,
  isPartner: PropTypes.bool,
  logScreenView: PropTypes.bool,
  meta: PropTypes.shape({}),
  name: PropTypes.string,
  primaryImageObjDetails: PropTypes.shape({
    contentUrl: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
  }),
  productSchema: PropTypes.shape({
    product: PropTypes.shape({}),
    helpers: PropTypes.shape({}),
  }),
  schema: PropTypes.object, // eslint-disable-line
  showAppBanner: PropTypes.bool,
  title: PropTypes.string,
  withTopAd: PropTypes.bool,
};

Page.defaultProps = {
  activeNavKey: undefined,
  adParams: undefined,
  bannerAds: [],
  breadcrumbSchema: undefined,
  className: undefined,
  compact: false,
  description: DESCRIPTION,
  isPartner: false,
  logScreenView: true,
  meta: null,
  name: undefined,
  primaryImageObjDetails: undefined,
  productSchema: undefined,
  schema: undefined,
  showAppBanner: false,
  title: TITLE,
  withTopAd: false,
};
