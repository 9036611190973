import PropTypes from 'prop-types';
import AddressProps from './address';

const Station = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  numRatings: PropTypes.number,
  enterprise: PropTypes.bool,
  address: AddressProps,
  payStatus: PropTypes.shape({
    isPayAvailable: PropTypes.bool,
  }),
  phone: PropTypes.string,
});

export default Station;
